<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <btn-card icon="fas fa-plus" style="margin-bottom: 5vh;" type="primary" @click="createFile">
          <template slot="desc">
            <h4>NEW</h4>
            <span>Create new file</span>
          </template>
        </btn-card>
        <div class="nav-wrapper">
          <h4>Library</h4>
          <btn-card icon="fas fa-clock" @click="$router.push({ path: '/files' })">
            <template slot="desc">
              <span>Recent files</span>
            </template>
          </btn-card>
          <btn-card icon="fas fa-star" @click="$router.push({ path: '/favorites' })">
            <template slot="desc">
              <span>Favorites</span>
            </template>
          </btn-card>
          <!-- <btn-card icon="fas fa-cloud-upload-alt">
            <template slot="desc">
              <span>Import file</span>
            </template>
          </btn-card> -->
        </div>
        <!-- <div class="nav-wrapper">
          <h4>Shared</h4>
          <div class="nav-wrapper__links">
            <sidebar-link to="/recentFiles" :name="$t('sidebar.dashboard')" icon="fas fa-folder"/>
            <sidebar-link to="/dashboard" :name="$t('sidebar.dashboard')" icon="fas fa-folder"/>
          </div>
        </div> -->
      </template>
    </side-bar>
    <div class="main-panel">

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";

import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    createFile() {
      this.$router.push({ path: `/d/${uuidv4()}` });
    }
  }
};
</script>
