const file = {
    state: { 
        files: [],
        favorites: []
    },
    mutations: { 
        registerFile (state, file) {
            const isRegistered = state.files.indexOf(file);

            if(isRegistered > -1) {
                state.files.splice(isRegistered, 1);
            }
            // insert at the first position
            state.files.splice(0, 0, file);
        },
        toggleFavorites (state, file) {
            const isAFav = state.favorites.indexOf(file);

            if(isAFav > -1) {
                state.favorites.splice(isAFav, 1);
            } else {
                state.favorites.push(file);
            }
        },
    },
    actions: { 
    },
    getters: {
        isAlreadyAFav: (state) => (file) => {
            return state.favorites.indexOf(file) != 1;
        }
    }
}

export default file;