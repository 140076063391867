<template>
  <div>
    <notifications></notifications>
    <RecentFiles id="quick-search"/>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
  import RecentFiles from "@/components/Shortcuts/RecentFiles";
  export default {
    components: {
      RecentFiles
    },
    methods: {
      disableRTL() {
        if (!this.$rtl.isRTL) {
          this.$rtl.disableRTL();
        }
      },
      toggleNavOpen() {
        let root = document.getElementsByTagName('html')[0];
        root.classList.toggle('nav-open');
      },
    },
    mounted() {
      this.$watch('$route', this.disableRTL, { immediate: true });
      this.$watch('$sidebar.showSidebar', this.toggleNavOpen);

      document.onkeydown = (event) => {
        if((event.ctrlKey || event.metaKey) && (event.key == "p" || event.charCode == 16 || event.charCode == 112 || event.keyCode == 80) ){
          event.preventDefault();
          this.$bvModal.show("quick-search");
        }
      }
    }
  };
</script>

<style lang="scss"></style>
