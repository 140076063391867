import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import user from "./user";
import file from "./file";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
});

const store = new Vuex.Store({
    modules: {
        user,
        file
    },
    plugins: [vuexLocal.plugin]
})

export default store;