import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Editor = () => import(/* webpackChunkName: "editor" */"@/pages/Editor.vue");
const Login = () => import(/* webpackChunkName: "login" */ "@/pages/Login.vue");
const Explorer = () => import(/* webpackChunkName: "explorer" */"@/pages/Explorer.vue");
const Favorites = () => import(/* webpackChunkName: "favorites" */ "@/pages/Favorites.vue");
const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
const Typography = () => import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
const TableList = () => import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/d/2fefdc1d-ca67-4c05-9ce4-a36cb2eef31c",
    children: [
      {
        path: "d/:id",
        name: "document",
        component: Editor
      },
      {
        path: "files",
        name: "files",
        component: Explorer
      },
      {
        path: "favorites",
        name: "favorites",
        component: Favorites
      },
      {
        path: "maps",
        name: "maps",
        component: Maps
      },
      {
        path: "typography",
        name: "typography",
        component: Typography
      },
      {
        path: "table-list",
        name: "table-list",
        component: TableList
      }
    ]
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
