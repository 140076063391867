<template>
  <div class="theme-switch" >
    <i class="fas" @click="switchTheme" :class="{
      'fa-sun': isLight,
      'fa-moon': !isLight
    }"/>
  </div>
</template>
<script>
  export default {
    name: "theme-switch",
    data() {
      return {
        element: null,
        isLight: false
      }
    },
    mounted() {
      this.element = document.querySelector("body");
      this.updateState();
    },
    computed: {
    },
    methods: {
      switchTheme() {
        this.element.classList.toggle("light-theme");
        this.updateState();
      },
      updateState() {
        this.isLight = this.element.classList.contains("light-theme");
      }
    }
  }
</script>
