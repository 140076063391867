<template>
    <card class="card-btn" :class="[type && `card-${type}`]"  @click="click">
        <div class="card-btn__desc">
            <slot name="desc"></slot>
        </div>
        <i :class="icon"></i>
    </card>
</template>
<script>
import Card from "./Card.vue";

export default {
  name: "btn-card",
  components: {
    Card
  },
  props: {
        title: {
            type: String,
            description: "Card title"
        },
        icon: {
            type: String,
            description: "Icon name"
        },
        type: {
            type: String,
            description: "Card type (e.g primary/danger etc)"
        },
        headerClasses: {
            type: [String, Object, Array],
            description: "Card header css classes"
        },
        bodyClasses: {
            type: [String, Object, Array],
            description: "Card body css classes"
        },
        footerClasses: {
            type: [String, Object, Array],
            description: "Card footer css classes"
        }
    },
    methods: {
        click() {
            this.$emit("click");
        }
    }
};
</script>
<style>
</style>
