const user = {
    state: { 
        username: null,
        color: null
    },
    mutations: { 
        updateUsername (state, username) {
            state.username = username;
        },
        updateColor (state, color) {
            state.color = color;
        },
    },
    actions: { 

    },
    getters: {
        user(state) {
            return {
                name: state.username || "Anonymous",
                color: state.color
            }
        },
        isLoggedIn(state) {
            return state.username !== null && state.color !== null;
        }
    }
}

export default user;