<template>
  <div class="inLine-file__wrapper" @click="click">
    <div class="inLine-file__wrapper-icon">
      <i class="fas fa-file-code" />
    </div>
    <div class="file__wrapper-description">
      <span>{{file}}</span>
    </div>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      file: {
        required: true,
      }
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
      click() {
        this.$emit("click");
      }
    }
  }
</script>