import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store";

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "active",
  mode: 'history',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !store.getters.isLoggedIn) next({ name: 'login' });
  else if(to.name == 'login' && store.getters.isLoggedIn) next({ path: '/' });
  else next();
})

export default router;