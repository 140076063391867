<template>
  <b-modal class="quick-search" :id="id" hide-footer hide-header ref="modal">
    <div class="quick-search__tools">
      <b-form-input v-model="search" placeholder="Search files by name ..." ref="search" autofocus></b-form-input>
    </div>
    <div class="quick-search__wrapper">
      <div class="quick-search__wrapper-files" ref="files">
        <File v-for="(file, key) in files" :key="key" :file="file" @click="openFile(file)" :tabindex="0" @keyup.enter.native="openFile(file)"/>
      </div>
    </div>
  </b-modal>
</template>
<script>
  import File from "@/components/Files/InLineFile";

  export default {
    components: {
      File
    },
    props: {
      id: {
        required: true
      }
    },
    data() {
      return {
        search: "",
        files: []
      }
    },
    mounted() {
      this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
        if(modalId == this.id) {
          this.$nextTick().then(() => {
            const firstFile = this.$refs.search;
            if(firstFile) {
              firstFile.$el.querySelector("input")?.focus();
            }
          });
        }
      });
      this.files = this.$store.state.file.files;
    },
    methods: {
      openFile(file) {
        this.$router.push({ path: `/d/${file}`});
        this.$refs.modal.hide();
      } 
    },
    watch: {
      search: function(newSearch, oldSearch) {
        this.files = this.$store.state.file.files.filter(function (str) { return str.indexOf(newSearch) !== -1; });
      }
    }
  }
</script>
<style lang="scss">
.modal.show .modal-dialog {
  transform: translate(0, 5vh) !important;
}

.quick-search__tools {
  margin-bottom: 1em;
}
</style>